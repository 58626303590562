import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// reactstrap components
import { Container, Row } from "reactstrap";
import { connect } from "react-redux";
// import { loggedIn } from "../services/auth";

// core components
import AuthFooter from "components/Footers/AuthFooter.jsx";

import routes from "../authRoutes";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  
  render() {
    const token = localStorage.getItem("token");
    if (token) {
      return <Redirect to="/admin/index" />;
    }
    return (
      <>
        <div className="main-content">
          <div className="header py-6 py-lg-6"> </div>
          {/* Page content */}
          <Container className="mt--5 pb-5">
            {/* <AuthNavbar /> */}
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}
const mapStateToProps = state => ({
  ...state
});
export default connect(mapStateToProps, {})(Auth);

// export default Auth;
