import React from "react";
import api from "../../../../services/api";
import logo from "../../../../assets/img/theme/loading.gif";
import { Link } from "react-router-dom";
import { Card, Pagination, Media, Table, Container, Row, Input, InputGroup, Label, Button } from "reactstrap";
import Paginate from "react-paginate";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

class TableDoctor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: "",
      error: null,
      doctors: [],
      paginate: null,
      searchDoc: "",
      isLoading: false,
      statusAccount: "",
      statusBrass: "",
      searchFields: ["searchDoc", "statusAccount", "statusBrass"],
    };
  }

  async componentDidMount() {
    await this.getDoctors();
  }

  getDoctors = async (event = null, param = null) => {
    const page = event ? (event.selected += 1) : null;

    let params = { page };

    if (param) {
      params = { ...params, ...param };
    }

    this.setState({ isLoading: true });

    const { data } = await api.get("painel/doctors/all", {
      params: { ...params },
    });

    this.setState({
      doctors: data.data,
      paginate: data.pagination,
      isLoading: false,
    });
  };

  getOnlyCertificate = ({ certificates }) => {
    const certificate = certificates.filter((item) => {
      return item.type === "Specialty";
    });

    if (certificate.length <= 0) return "Sem especialidade";

    return certificate[0].county;
  };

  doctorBodyTableList = () => {
    const { doctors, isLoading } = this.state;

    if (isLoading) {
      return (
        <tr>
          <td>
            <span className="w-100">
              <img src={logo} width="50px" alt="loading..." />
              <span className="m-4">Carregando registros... </span>
            </span>
          </td>
        </tr>
      );
    }

    return doctors.length ? (
      doctors.map((item) => {
        const avatar = `${item.avatar || "default_avatar.png"}`;

        return (
          <tr key={item.id}>
            <th scope="row">
              <Media className="align-items-center">
                <button className="avatar rounded-circle mr-3" onClick={(e) => e.preventDefault()}>
                  <img alt="Imagem doutor" src={process.env.REACT_APP_BASE_URL_API + `/documents/${avatar}`} className="h-100" />
                </button>
                <Media>
                  <span className="mb-0 text-sm">
                    {item.name} {item.last_name}
                  </span>
                </Media>
              </Media>
            </th>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.email}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">
                  {this.getOnlyCertificate(item)} - {item.crm}
                </span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.label_status_financeiro}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.label_status_braspag}</span>
              </div>
            </td>
            <td className="text-right">
              <div>
                <Link
                  className="btn btn-success"
                  to={{
                    pathname: `/admin/evaluation/${item.id}`,
                    doctor: item,
                  }}
                >
                  Avaliar
                </Link>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>
          <span className="w-100">
            <span className="m-4"> Não há registro </span>
          </span>
        </td>
      </tr>
    );
  };

  paginateTable = () => {
    const { paginate } = this.state;

    if (paginate === null) return <> </>;

    const { totalPages } = paginate;

    return (
      <Pagination className="justify-content-center d-flex mt-3">
        <div className="d-flex ul-in-line">
          <Paginate
            pageCount={totalPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={this.searchDoctor}
            previousLabel="Anterior"
            nextLabel="Proximo"
            activeClassName="ul-li-active"
          />
        </div>
      </Pagination>
    );
  };

  searchDoctor = async (e) => {
    const { searchFields } = this.state;

    let params = {};

    await searchFields.map((item) => {
      if (this.state[item] !== "") {
        params[item] = this.state[item];
        return params[item];
      }

      return "Não localizado";
    });

    this.getDoctors(e, { ...params });
  };

  render() {
    return (
      <>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <div className="d-flex justify-content-between flex-row align-items-end">
                  <InputGroup style={{ padding: 10 }}>
                    {/* <InputGroupAddon addonType="prepend">
                      Pesquisa
                    </InputGroupAddon> */}
                    <Input value={this.state.searchDoc} placeholder="Pesquisa" onChange={(e) => this.setState({ searchDoc: e.target.value })} />
                    {/* <InputGroupAddon addonType="prepend"> */}
                    <Button color="primary" type="button" onClick={() => this.searchDoctor()}>
                      <i className="fas fa-search"></i>
                    </Button>
                    {/* </InputGroupAddon> */}
                  </InputGroup>

                  <div className="d-flex flex-column w-100" style={{ padding: 7 }}>
                    <Label for="filterStore">Situação Cadastro</Label>

                    <Input
                      type="select"
                      name="select"
                      id="filterStore"
                      onChange={async (e) => {
                        await this.setState({ statusAccount: e.target.value });

                        this.searchDoctor();
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="3">Aprovado</option>
                      <option value="2">Pendente</option>
                      <option value="1">Aguardando análise</option>
                    </Input>
                  </div>

                  <div className="d-flex flex-column w-100" style={{ padding: 7 }}>
                    <Label for="filterBank">Status Financeiro</Label>

                    <Input
                      type="select"
                      name="select"
                      id="filterBank"
                      onChange={async (e) => {
                        await this.setState({ statusBrass: e.target.value });

                        this.searchDoctor();
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="Approved">Aprovado</option>
                      <option value="Rejected">Pendente</option>
                      <option value="Underanalysis">Aguardando análise</option>
                    </Input>
                  </div>
                </div>

                <Table className="align-items-center table-hover" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Doutores</th>

                      <th scope="col">E-mail</th>

                      <th scope="col">Conselho</th>

                      <th scope="col">Situação Cadastro</th>

                      <th scope="col">Status Financeiro</th>

                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>{this.doctorBodyTableList()}</tbody>
                </Table>
                <nav>{this.paginateTable()}</nav>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default TableDoctor;
