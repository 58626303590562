import React from "../../../../node_modules/react";
import logo from "../../../assets/img/theme/loading.gif";
import api from "../../../services/api";
import { Card, CardHeader, CardBody, FormGroup, Form, Container, Row, Col, Button, Badge, Input } from "../../../../node_modules/reactstrap";
import swal from "sweetalert2";

import ModalDoctor from "./modal/ModalDoctor";
import UserHeader from "../../../components/Headers/UserHeader.jsx";
import TableCertificates from "./table/tableListCertificates.jsx";

class doctorAvaliation extends React.Component {
  state = {
    name: "",
    last_name: "",
    cpf: "",
    email: "",
    estado: "",
    cidade: "",
    error: "",
    doctor: {},
    account: {},
    isModalOpen: false,
    labelModal: "",
    status: "",
    denialreason: "",
    address: "",
    isLoading: false,
    certificates: [],
    crm: {},
    degree: {},
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.getInfoDoctor(params.id);
  }

  getInfoDoctor = async (id) => {
    this.toggleLoading();

    const { data } = await api.get(`/painel/show/${id}/doctor`);

    let [crm, degree] = [{}];

    const certificates = data.data.certificates.filter((item) => {
      if (item.type === "Degree") degree = item;

      if (item.type === "CRM") crm = item;

      return item.type === "Specialty";
    });

    this.setState({
      doctor: data.data,
      address: data.data.address || [],
      account: data.data.account.length > 0 ? data.data.account[0] : {},
      doctorId: id,
      isLoading: false,
      certificates,
      crm,
      degree,
    });
  };

  getInfoDoctorById = async () => {
    const { doctor } = this.state;
    const { data } = await api.get(`/doctors/${doctor.id}`);

    this.setState({
      doctor: data.data,
      address: data.data.address,
      denialreason: "",
    });
  };

  toggleModalContext = (actual, label, status) => {
    const { isModalOpen } = this.state;

    this.setState({
      isModalOpen: !isModalOpen,
      actualModal: actual,
      labelModal: label,
      status,
    });
  };

  resendBraspag = async (doctor) => {
    if (doctor.hasOwnProperty("merchant_id") && doctor.merchant_id) {
      swal.fire("Ops", "Esse(a) profissional já possui cadastro na Braspag", "warning");
      return;
    }

    try {
      const { data } = await api.post(`/doctors/subordinate/send/informations-from-admin`, {
        doctor_id: doctor.id,
      });

      if (data.includes("Dados inválidos")) {
        swal.fire("Ops", data, "error");
        return;
      }

      if (data) {
        swal.fire("Concluído", "Dados do médico reenviados para análise da Braspag", "success");
      }
    } catch (error) {
      console.log(error);
      swal.fire("Ops", "Houve um erro ao reenviar os dados para a Braspag", "error");
    }
  };

  contentAprov = () => {
    return (
      <div className="d-flex justify-content-center w-100">
        <h2> Você tem certeza que deseja Aprovar ? </h2>
      </div>
    );
  };

  contentReprov = () => {
    const { denialreason } = this.state;

    return (
      <>
        <div className="d-flex justify-content-center w-100">
          <h2>
            {" "}
            Você tem certeza que deseja <Badge color="danger"> Reprovar </Badge> ?{" "}
          </h2>
        </div>
        <div className="d-flex mt-4 w-100">
          <Input
            placeholder="Motivo da reprovação, foi reprovado por...."
            rows="4"
            type="textarea"
            className="form-control-alternative"
            value={denialreason}
            onChange={(e) => this.setState({ denialreason: e.target.value })}
          />
        </div>
      </>
    );
  };

  contentDelete = () => {
    return (
      <>
        <div className="d-flex justify-content-center w-100">
          <h2>
            {" "}
            Você tem certeza que deseja <Badge color="danger"> EXCLUIR </Badge> ?{" "}
          </h2>
        </div>
      </>
    );
  };

  contentModal = () => {
    const { actualModal } = this.state;

    if (actualModal === "Reprov") return this.contentReprov();
    if (actualModal === "Excluir") return this.contentDelete();

    return this.contentAprov();
  };

  loading = () => (
    <div className="w-100 d-flex justify-content-center">
      <img src={logo} width="50px" alt="loading..." />
      <span className="m-4"> Carregando registros... </span>
    </div>
  );

  toggleModal = () => {
    const { isModalOpen } = this.state;

    this.setState({
      isModalOpen: !isModalOpen,
      denialreason: "",
    });
  };

  toggleLoading = () => {
    const { isLoading } = this.state;

    this.setState({ isLoading: !isLoading });
  };

  handleSubmit = async (e) => {
    let { status, denialreason, doctor, actualModal } = this.state;

    if (actualModal !== "Excluir") {
      if (typeof denialreason === "undefined") denialreason = null;

      await api.post(`doctors/notification/account`, {
        status,
        denialreason,
        doctorId: doctor.id,
      });

      this.toggleModal();

      this.toggleLoading();

      await this.getUpdateUserData();

      this.toggleLoading();
    }

    if (actualModal === "Excluir") {
      try {
        await api.post(`doctors/force/delete/${doctor.id}`);

        this.props.history.goBack();
      } catch (error) {
        console.log(error);
      }
    }
  };

  getUpdateUserData = async () => {
    const { doctor } = this.state;
    const { data } = await api.get(`/doctors/${doctor.id}`);

    this.setState({ doctor: data.data });
  };

  render() {
    const { doctor, isModalOpen, labelModal, address, isLoading, account, certificates, crm, degree } = this.state;

    return (
      <>
        <UserHeader doctor={doctor} />

        <ModalDoctor isOpen={isModalOpen} toggle={() => this.toggleModal()} content={this.contentModal()} label={labelModal} handlesubmit={this.handleSubmit} />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="justify-content-center">
            <TableCertificates certificates={certificates} doctor={doctor} crm={crm} degree={degree} getCertificate={(id) => this.getInfoDoctor(id)} />
          </Row>

          <Row className="justify-content-center mt-5 container-fluid">
            <Col className="order-xl-2">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0 p-3">
                  <Row className="align-items-center justify-content-center">
                    <Col xs="8 text-center">
                      <h3 className="mb-0"> Informações </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-2">
                  {isLoading ? (
                    this.loading()
                  ) : (
                    <>
                      <Form>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label font-size-label">Doutor:</label>
                                <span className="mb-0"> {doctor.hasOwnProperty("formal_name") ? doctor.formal_name : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label">Nome:</label>
                                <span className="mb-0"> {doctor.hasOwnProperty("name") ? doctor.name : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  E-mail:
                                </label>
                                <span className="mb-0"> {doctor.hasOwnProperty("email") ? doctor.email : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Cpf:
                                </label>
                                <span className="mb-0"> {doctor.hasOwnProperty("cpf") ? doctor.cpf : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Crm:
                                </label>
                                <span className="mb-0"> {doctor.hasOwnProperty("crm") ? doctor.crm : ""} </span>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Crm-UF:
                                </label>
                                <span className="mb-0"> {doctor.hasOwnProperty("crm_uf") ? doctor.crm_uf : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Status cadastro:
                                </label>
                                <span className="mb-0"> {doctor.hasOwnProperty("label_status_financeiro") ? doctor.label_status_financeiro : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Status Financeiro:
                                </label>
                                <span className="mb-0"> {doctor.hasOwnProperty("label_status_braspag") ? doctor.label_status_braspag : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  MerchantId:
                                </label>
                                <span className="mb-0"> {doctor.hasOwnProperty("merchant_id") && doctor.merchant_id ? doctor.merchant_id : "Sem dado"} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label">Estado:</label>
                                <span className="mb-0"> {address.hasOwnProperty("uf_name") ? address.uf_name : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label">Cidade:</label>
                                <span className="mb-0"> {address.hasOwnProperty("city_name") ? address.city_name : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <div>
                                  {doctor.avatar ? (
                                    <img
                                      src={process.env.REACT_APP_BASE_URL_API + `/documents/${doctor.avatar}`}
                                      height="100"
                                      width="120"
                                      className="border-radius"
                                      alt={doctor.name}
                                    />
                                  ) : (
                                    <h5> Não possui foto</h5>
                                  )}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Bairro
                                </label>
                                <span className="mb-0"> {address.hasOwnProperty("neighborhood") ? address.neighborhood : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Rua:
                                </label>
                                <span className="mb-0"> {address.hasOwnProperty("street") ? address.street : ""} </span>
                              </FormGroup>

                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  CEP:
                                </label>
                                <span className="mb-0"> {address.hasOwnProperty("zipcode") ? address.zipcode : ""} </span>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Contato
                                </label>
                                <span className="mb-0"> {doctor.hasOwnProperty("phone") ? doctor.phone : ""} </span>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Banco:
                                </label>
                                <span className="mb-0"> {account.hasOwnProperty("bank_name") ? account.bank_name : ""} </span>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Agência:
                                </label>
                                <span className="mb-0"> {account.hasOwnProperty("agency_number") ? account.agency_number : ""} </span>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Digito da agência:
                                </label>
                                <span className="mb-0"> {account.hasOwnProperty("agency_digit") ? account.agency_digit : ""} </span>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Tipo de conta:
                                </label>
                                <span className="mb-0"> {account.hasOwnProperty("type_account") ? account.type_account : ""} </span>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Número da Conta:
                                </label>
                                <span className="mb-0"> {account.hasOwnProperty("number") ? account.number : ""} </span>
                              </FormGroup>
                              <FormGroup>
                                <label className="form-control-label font-size-label" htmlFor="input-email">
                                  Dígito da conta:
                                </label>
                                <span className="mb-0"> {account.hasOwnProperty("verifier_digit") ? account.verifier_digit : ""} </span>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>

                      <div className="d-flex justify-content-around p-5">
                        <Button className="btn btn-success" onClick={() => this.toggleModalContext("Aprov", "Aprovação", "3")}>
                          Aprovar
                        </Button>

                        <Button
                          className="btn btn-info"
                          component="a"
                          target="_blank"
                          href={`https://www.cfm.org.br/index.php?option=com_medicos&ufMedico=${doctor.crm_uf || ""}&crmMedico=${
                            doctor.crm || ""
                          }&municipioMedico=&tipoInscricaoMedico=&situacaoMedico=&detalheSituacaoMedico=&especialidadeMedico=&areaAtuacaoMedico=&g`}
                        >
                          Verificar no CFM
                        </Button>

                        <Button className="btn btn-info" onClick={() => this.resendBraspag(doctor)}>
                          Reenviar para Braspag
                        </Button>

                        <Button className="btn btn-warning" onClick={() => this.toggleModalContext("Reprov", "Motivo Reprovação", "2")}>
                          Reprovar
                        </Button>

                        <Button className="btn btn-danger" onClick={() => this.toggleModalContext("Reprov", "Motivo Bloqueio", "0")}>
                          Bloquear
                        </Button>

                        <Button className="btn btn-danger" onClick={() => this.toggleModalContext("Excluir", "Excluir definitivamente", "0")}>
                          Excluir definitivamente
                        </Button>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default doctorAvaliation;
