import React from "react";
import api from "../../../../services/api";
import logo from "../../../../assets/img/theme/loading.gif";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
} from "reactstrap";

class PaymentModal extends React.Component {
  state = {
    isLoading: false,
    message: "",
  };

  handleSubmit = async () => {
    this.toggleLoading();

    const { braspagPayment, paymnetId } = this.props;

    if (!braspagPayment.Payment.PaymentId) return;

    const params = {
      payment_id: braspagPayment.Payment.PaymentId,
      merchant_order_id: braspagPayment.MerchantOrderId
    }

    try {
      const { data } = await api.post(
        '/painel/payment/cancel',
        {...params}
      );

      this.props.updateInfoPaymnet(paymnetId)

      this.setState({ message: data.message });

      this.toggleLoading();
    } catch (error) {
      this.toggleLoading();
    }
  };

  cancelPayment = async () => {};

  toggleLoading = () => {
    const { isLoading } = this.state;

    this.setState({ isLoading: !isLoading });
  };

  render() {
    const { isLoading } = this.state;
    const { className } = this.props;

    return (
      <Modal
        toggle={this.props.toggle}
        {...this.props}
        size="lg"
        className={className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          charCode="X"
          className="text-center"
        >
          <div className="d-flex justify-content-center w-100">
            <h1> Cancelar pagamento </h1>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="d-flex justify-content-center w-100">
            {this.state.message !== "" ? (
              <h2> {this.state.message} </h2>
            ) : (
              <h2> Você tem certeza que cancelar o pagamento</h2>
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup size="lg">
            {isLoading ? (
              <span className="w-100">
                <img src={logo} width="50px" alt="loading..." />
              </span>
            ) : this.state.message === "" ? (
              <Button
                color="success"
                type="submit"
                onClick={(e) => this.handleSubmit()}
              >
                Sim
              </Button>
            ) : null}

            <Button onClick={this.props.toggle} type="button">
              Fechar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PaymentModal;
