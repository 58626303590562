import React, { useState, useEffect } from "react";
import { Card, Container, Input, Row, Col, Form, FormGroup, Label, Button, FormFeedback } from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import api from "../../../../services/api";
import axios from "axios";

const RegionalType = {
  MEDICO: 2,
  PACIENTE: 1,
};

function PanelSetRegionalFunc() {
  const [UF, setUF] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [regional, setRegional] = useState(null);

  const [name, setName] = useState("");
  const [states, setStates] = useState([]);
  const [occupation_id, setOccupation_id] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const params = useParams();

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      email: data.email,
      password: data.password,
      occupation_id: data.occupation_id,
      states: data.states.map((s) => s.id),
    };

    let postOrPutEndpoint = "regional/admin/create";

    if (params && params.id) {
      payload.id = params.id;
      postOrPutEndpoint = `/regional/admin/update/${params.id}`;
    }

    api
      .post(postOrPutEndpoint, payload)
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Médicos selecionados foram nomeados como Regionais',
            showConfirmButton: false,
            timer: 6000,
          })
          history.push("/admin/regional");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const endpoints = [await api.get("specialties/public/occupations", {}), await api.get("doctors/get/state", {})];

      if (params && params.id) {
        endpoints.push(await api.get("regional/admin/byid/" + params.id, {}));
      }

      axios
        .all(endpoints)
        .then(
          axios.spread((...responses) => {
            const ufs = responses[1].data;

            setOccupations(responses[0].data.data);
            setUF(ufs);

            setRegional(responses[2].data);

            setValue("name", responses[2].data.name);
            setValue("email", responses[2].data.email);
            setValue("occupation_id", responses[2].data.occupation_id);

            const savedStates = responses[2].data.states.split(", ").map((state) => {
              return ufs.find((s) => s.letter == state);
            });

            setValue("states", savedStates);
            setStates(savedStates);
          })
        )
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, []);

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <div className="container-set-regional">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <input
                          className={`form-control ${errors.name && errors.name.type === "required" ? "is-invalid" : ""}`}
                          placeholder="Nome"
                          type="text"
                          {...register("name", { required: "O campo nome é obrigatório", maxLength: 80 })}
                        />
                        <div className="invalid-feedback">{errors && errors.name && errors.name.message}</div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <input
                          className={`form-control ${errors.email && errors.email.type === "required" ? "is-invalid" : ""}`}
                          placeholder="E-mail"
                          type="email"
                          {...register("email", {
                            required: "O campo e-mail é obrigatório",
                            pattern:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />
                        <div className="invalid-feedback">{errors && errors.email && errors.email.message}</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <input
                          className={`form-control ${errors.password && errors.password.type === "required" ? "is-invalid" : ""}`}
                          placeholder="Senha"
                          type="password"
                          {...register("password", { required: params && params.id ? false : "O campo senha é obrigatório", maxLength: 80 })}
                        />
                        <div className="invalid-feedback">{errors && errors.password && errors.password.message}</div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <select
                          className={`form-control ${errors.occupation_id && errors.occupation_id.type === "required" ? "is-invalid" : ""}`}
                          id="occupation_id"
                          {...register("occupation_id", { required: "O campo Profissão é obrigatório" })}
                        >
                          {occupations.length > 0 &&
                            occupations.map((occupation) => {
                              return (
                                <option key={occupation.id} value={occupation.id}>
                                  {occupation.description}
                                </option>
                              );
                            })}
                        </select>
                        <div className="invalid-feedback">{errors && errors.occupation_id && errors.occupation_id.message}</div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        {UF && (
                          <Controller
                            name="states"
                            control={control}
                            rules={{ required: "O campo Estados é obrigatório" }}
                            render={({ field: { ref, ...field } }) => (
                              <>
                                <Multiselect
                                  isObject={true}
                                  {...field}
                                  inputRef={ref}
                                  placeholder=""
                                  emptyRecordMsg="Nenhum estado disponível"
                                  showArrow
                                  selectedValues={states}
                                  onRemove={(list, item) => {
                                    setValue("states", list, { shouldValidate: true });
                                  }}
                                  onSelect={(list, item) => {
                                    setValue("states", list, { shouldValidate: true });
                                  }}
                                  options={UF}
                                  displayValue="title"
                                />
                              </>
                            )}
                          />
                        )}
                        <div className="form-input-invalid">{errors && errors.states && errors.states.message}</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <button className="btn btn-success" type="submit">
                        Nomear
                      </button>
                      <Button color="danger" onClick={() => history.push("/admin/regional")}>
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default PanelSetRegionalFunc;
