import React from "react";
import { Card, Pagination, Table, Container, Row, Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Paginate from "react-paginate";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import api from "../../../../services/api";
import logo from "../../../../assets/img/theme/loading.gif";
import Swal from 'sweetalert2'

class ListEmbassador extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      regionals: [],
      paginate: null,
      isLoading: false,
      search: "",
      dateInitial: "",
      dateFinal: "",
      status: "",
      searchFields: ["status", "dateInitial", "dateFinal", "search"],
      modalIsOpen: false,
      regionalSelected: "",
      statusSelected: "",
    };
  }

  async componentDidMount() {
    await this.getRegional();
  }

  getRegional = async (event, param = null) => {
    const page = typeof event !== "undefined" ? (event.selected += 1) : null;

    let params = { page };

    if (param) {
      params = { ...params, ...param };
    }

    this.setState({ isLoading: true });

    const { data } = await api.get("regional/admin/all", {
      params: { ...params },
    });

    this.setState({
      regionals: data.data,
      paginate: data.pagination,
      isLoading: false,
    });
  };

  openModalStatus = (regionalId) => {
    this.props.history.push("/admin/regional/edit/1");
  };

  updateStatus = async () => {
    const { statusSelected, regionalSelected } = this.state;
    await api.post(`regional/update/${regionalSelected}`, {
      status: statusSelected,
    });
    this.setState({
      modalIsOpen: false,
    });
    alert("Status alterado com Sucesso!");
    await this.getRegional();
  };

  delete = async (id) => {
    
    await Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      text: "Será irreversível!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText:'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText:'Excluir',
      
    }).then((result) => {
      if (result.isConfirmed) {
         api.delete(`regional/admin/delete/${id}`);
         this.getRegional();
        Swal.fire(
          'Registro deletado com sucesso!',
        )
      }
    })

   
  } 


  Regionals = () => {
    const { regionals, isLoading } = this.state;

    if (isLoading) {
      return (
        <tr>
          <td>
            <span className="w-100">
              <img src={logo} width="50px" alt="loading..." />
              <span className="m-4">Carregando registros... </span>
            </span>
          </td>
        </tr>
      );
    }

    return regionals.length ? (
      regionals.map((item) => {
        return (
          <tr key={item.id}>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.id}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.name ? item.name : ""}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.occupation ? item.occupation.description : ""}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.states ? item.states : "Nenhum UF definido"}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.total_doctors ? item.total_doctors : "0"}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.total_appointments ? item.total_appointments : "0"}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.total_to_receive ? item.total_to_receive : "0,00"}</span>
              </div>
            </td>
            {/* <td>
              <div className="d-flex align-items-center">
                {item.status !== 0 ? (
                  item.status === 2 ? (
                    <span className="mr-2 status-regional-active">Ativo</span>
                  ) : (
                    <span className="mr-2 status-regional-to-canceled">A Cancelar</span>
                  )
                ) : (
                  <span className="mr-2 status-regional-canceled">Cancelado</span>
                )}
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.confirmation !== 0 ? "Sim" : "Não"}</span>
              </div>
            </td> */}
            <td>
              <div>
                <Link
                  to={{
                    pathname: `/admin/regional/${item.id}/summary`,
                  }}
                >
                  <button className="btn btn-success mb-2">Relatório</button>
                </Link>
              </div>
              <div>
                <Link
                  className="btn btn-info"
                  to={{
                    pathname: `/admin/regional/edit/${item.id}`,
                  }}
                >
                  Editar
                </Link>
                <Button
                  className="btn btn-danger"
                  onClick={() => this.delete(item.id)}
                >
                  Excluir
                </Button>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>
          <span className="w-100">
            <span className="m-4"> Não há registro </span>
          </span>
        </td>
      </tr>
    );
  };

  Paginate = () => {
    const { paginate } = this.state;

    if (paginate === null) return <> </>;

    const { totalPages } = paginate;

    return (
      //Pagination Pagamentos
      <Pagination className="justify-content-center d-flex mt-3">
        <div className="d-flex ul-in-line">
          <Paginate
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.search}
            previousLabel="Anterior"
            nextLabel="Proximo"
            activeClassName="ul-li-active"
          />
        </div>
      </Pagination>
    );
  };

  search = async (e) => {
    const { searchFields } = this.state;

    let params = {};

    await searchFields.map((item) => {
      if (this.state[item] !== "") {
        params[item] = this.state[item];
      }

      return params[item];
    });

    return this.getRegional(e, { ...params });
  };

  render() {
    return (
      <>
        <Container className="mt--7" fluid>
          <Modal isOpen={this.state.modalIsOpen}>
            <ModalHeader>Alterar Status</ModalHeader>
            <ModalBody>
              Alterar o Status do embaixador:
              <select className="form-control select-regional">
                <option>Selecione o Status</option>
                <option
                  onClick={() => {
                    this.setState({ statusSelected: 2 });
                  }}
                >
                  {" "}
                  Ativo{" "}
                </option>
                <option
                  onClick={() => {
                    this.setState({ statusSelected: 1 });
                  }}
                >
                  {" "}
                  A Cancelar{" "}
                </option>
                <option
                  onClick={() => {
                    this.setState({ statusSelected: 0 });
                  }}
                >
                  {" "}
                  Cancelado{" "}
                </option>
              </select>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  this.updateStatus();
                }}
              >
                Salvar
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({ modalIsOpen: false });
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Row>
            <div className="col">
              <Card className="shadow">
                <div className="d-flex justify-content-between flex-row align-items-end">
                  <InputGroup style={{ padding: 10 }}>
                    <InputGroupAddon addonType="prepend">Pesquisa</InputGroupAddon>
                    <Input value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} />
                    <InputGroupAddon addonType="prepend">
                      <Button color="primary" type="button" onClick={() => this.search()}>
                        <i className="fas fa-search"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>

                  <div className="container-buttons-regional">
                    <button className="button-regional">Exportar</button>
                    <Link to="/admin/regional/set">
                      <button className="button-regional">Adicionar Regional</button>
                    </Link>
                  </div>
                </div>

                <Table className="align-items-center table-hover" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Profissão</th>
                      <th scope="col">UFs</th>
                      <th scope="col">Médicos Cadastrados</th>
                      <th scope="col">Consultas Realizadas</th>
                      <th scope="col">Total à Receber</th>
                      {/* <th scope="col">Status</th> */}
                      {/* <th scope="col">Confirmação</th> */}
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>{this.Regionals()}</tbody>
                </Table>
                <nav>{this.Paginate()}</nav>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ListEmbassador;
