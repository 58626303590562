import React from 'react';
import { Card, Table, Container, Row, } from "reactstrap";

import api from '../../../../services/api';
import logo from '../../../../assets/img/theme/loading.gif';

class PanelPaymentAmbassador extends React.Component{
    constructor(props) {
        super(props);
    
        this.state = {
            error: null,
            payment: [],
            ambassador: {},
            paginate: null,
            isLoading: false,
            search: "",
            dateInitial: "",
            dateFinal: "",
            status: "",
            searchFields: ["status", "dateInitial", "dateFinal", "search"],
        };
    }

    async componentDidMount() {
      await this.Ambassador()

    }

    Ambassador = async () => {
      const { data } = await api.get(`ambassador/byid/${this.props.Ambassador.id}`)
      this.setState({
        ambassador: data
      })
    }

    toPayment = () => {
        const { ambassador } = this.state;
        return (
          <tr>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{ambassador.id}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{'Aberto'}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">{ambassador.doctors_appointments}</div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{ambassador.patients_appointments}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{ambassador.total_appointments}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{ambassador.to_receive}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <button className="btn btn-success"> Pagar</button>
              </div>
            </td>        
          </tr>
        );
    };

    Payments = () => {
      const { payment, isLoading } = this.state;
  
      if (isLoading) {
        return (
          <tr>
            <td>
              <span className="w-100">
                <img src={logo} width="50px" alt="loading..." />
                <span className="m-4">Carregando registros... </span>
              </span>
            </td>
          </tr>
        );
      }

      return payment.length ? (
          payment.map((item) => {
          return (
            <tr key={item.id}>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{item.id}</span>
                </div>
              </td>
              {/* <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{item.name ? item.name : ""}</span>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">{item.type ? item.type : ""}</div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{item.total_indicated ? item.total_indicated : "0"}</span>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{item.received ? item.received : "0.00"}</span>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{item.to_receive ? item.to_receive : "0.00"}</span>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="mr-2">{item.total_appointments ? item.total_appointments : "0"}</span>
                </div>
              </td> */}         
            </tr>
          );
        })
      ) : (
        <tr>
          <td>
            <span className="w-100">
              <span className="m-4"> Não há registro </span>
            </span>
          </td>
        </tr>
      );
  };

    render(){
        return(
            <>
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                        <Card className="shadow">
                            <div className="d-flex justify-content-between flex-row align-items-end p-2">
                                <h1>{this.state.ambassador.name}</h1>
                                <button className="button-ambassador">Exportar</button>
                            </div>

                              <Table className="align-items-center table-hover" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Número</th>

                                    <th scope="col">Status</th>

                                    <th scope="col">Consultas medicos</th>

                                    <th scope="col">Consultas paciente</th>

                                    <th scope="col">Total Consultas</th>

                                    <th scope="col">Valor</th>

                                    <th scope="col">Data pag.</th>
                                </tr>
                            </thead>
                            <tbody>{this.toPayment()}</tbody>
                            <tbody>{this.Payments()}</tbody>
                            </Table>
                            <nav>{}</nav>
                        </Card>
                        </div>
                    </Row>
                </Container>            
            </>
        );
    }
}

export default PanelPaymentAmbassador;