import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { NavbarBrand } from "reactstrap";

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
          <div className="d-flex justify-content-center">
            <NavbarBrand to="/" tag={Link}>
              <img
                style={{ width: 250, height: "auto" }}
                alt="Logo do Médicos do Brasil"
                src={
                  process.env.REACT_APP_FRONT_BASE_PATH + "/assets/images/mdb-transparente-312x163.png"
                }
              />
            </NavbarBrand>
          </div>
      </>
    );
  }
}

export default AdminNavbar;
