import React from "react";
import Header from "components/Headers/Header.jsx";
import { Container } from "reactstrap";
import ListRegional from "./table/ListRegional.jsx";

class informationRegional extends React.Component {
  state = {};
  render() {
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <ListRegional {...this.props} />
        </Container>
      </>
    );
  }
}

export default informationRegional;
