export const Types = {
  AUTH_REQUEST: "subject/AUTH_REQUEST",
  AUTH_RESPONSE: "subject/AUTH_RESPONSE",
  AUTH_SUCCESS: "subject/AUTH_SUCCESS",
  AUTH_SUCCESS_REGIONAL: "subject/AUTH_SUCCESS_REGIONAL",
  AUTH_ERROR: "subject/AUTH_ERROR",
  AUTH_ONDATA: "subject/AUTH_ONDATA",
  AUTH_ERROR_PANEL: "subject/AUTH_ERROR_PANEL",
  AUTH_CLEAR_ERROR_PANEL: "subject/AUTH_CLEAR_ERROR_PANEL",
  AUTH_ERROR_CLEAR: "subject/AUTH_ERROR_CLEAR",
  SET_DASH_INFO: "subject/SET_DASH_INFO",
};

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: true,
  erroPainel: false,
  dashInfo: {
    appointments: 0,
    doctors: 0,
    patients: 0,
  },
};

export const Creators = {
  authorize: (email, password) => ({
    type: Types.AUTH_REQUEST,
    payload: { email, password },
  }),

  authResponse: (data) => ({
    type: Types.AUTH_RESPONSE,
    payload: { data },
  }),

  authData: (data) => ({
    type: Types.AUTH_ONDATA,
    payload: { data },
  }),

  authSuccess: (data) => ({
    type: Types.AUTH_SUCCESS,
    payload: { data },
  }),

  authSuccessRegional: (data) => ({
    type: Types.AUTH_SUCCESS_REGIONAL,
    payload: { data },
  }),

  authError: (data) => ({
    type: Types.AUTH_ERROR,
    payload: { data },
  }),

  setDashInfo: (data) => ({
    type: Types.SET_DASH_INFO,
    payload: { data },
  }),

  clearAuthError: () => ({
    type: Types.AUTH_ERROR_CLEAR,
  }),

  setErrorPainel: () => ({
    type: Types.AUTH_ERROR_PANEL,
  }),

  clearErrorPainel: () => ({
    type: Types.AUTH_CLEAR_ERROR_PANEL,
  }),
};

const subject = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.AUTH_SUCCESS:
      return {
        ...state,
        data: payload.data,
        entity: "doctor",
        loading: false,
      };

    case Types.AUTH_SUCCESS_REGIONAL:
      return {
        ...state,
        data: payload.data,
        entity: "regional",
        loading: false,
      };

    case Types.AUTH_ONDATA:
      return {
        ...state,
        data: payload.data,
        loading: false,
      };

    case Types.AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.message,
      };

    case Types.SET_DASH_INFO:
      return {
        ...state,
        dashInfo: payload.data,
      };

    case Types.AUTH_ERROR_PANEL:
      return {
        ...state,
        erroPainel: "Você não possui acesso ao painel.",
      };

    case Types.AUTH_CLEAR_ERROR_PANEL:
      return {
        ...state,
        erroPainel: false,
      };

    case Types.AUTH_ERROR_CLEAR:
      return {
        ...state,
        error: false,
      };

    default:
      return state;
  }
};

export default subject;
