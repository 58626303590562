import React from 'react'


import Header from "components/Headers/Header.jsx";
import { Container } from "reactstrap";
import PanelPaymentAmbassador from './PanelPaymentAmbassador.jsx'

class paymentAmbassador extends React.Component{

    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        
        super(props)    

    }

    render(){
        return(
            <>
            <Header />
            <Container className="mt--7" fluid>
                <PanelPaymentAmbassador Ambassador={this.props.match.params}/>
            </Container>
            </>
        )
    }
}

export default paymentAmbassador;