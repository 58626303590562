import React from 'react';
import { Card, Pagination, Table, Container, Row, Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { Link } from 'react-router-dom';
import Paginate from 'react-paginate';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import api from '../../../../services/api';
import logo from '../../../../assets/img/theme/loading.gif';

class ListEmbassador extends React.Component{
    constructor(props) {
        super(props);
    
        this.state = {
            error: null,
            ambassadors: [],
            paginate: null,
            isLoading: false,
            search: "",
            dateInitial: "",
            dateFinal: "",
            status: "",
            searchFields: ["status", "dateInitial", "dateFinal", "search"],
            modalIsOpen: false,
            ambassadorSelected: '',
            statusSelected: '',
        };
    }

    async componentDidMount() {
        await this.getAmbassador();
    }

    getAmbassador = async (event, param = null) => {
        const page = typeof event !== "undefined" ? (event.selected += 1) : null;
    
        let params = { page };
    
        if (param) {
          params = { ...params, ...param };
        }
    
        this.setState({ isLoading: true });
    
        const { data } = await api.get("ambassador/all", {
          params: { ...params },
        });
        console.log(data)

        this.setState({
            ambassadors: data.data,
            paginate: data.pagination,
            isLoading: false,
        });
    };

    openModalStatus = (ambassadorId) => {
      this.setState({
        modalIsOpen: true,
        ambassadorSelected: ambassadorId
      })
    }

    updateStatus = async () => {
      const { statusSelected, ambassadorSelected } = this.state
      await api.post(`ambassador/update/${ambassadorSelected}`, {status: statusSelected})
      this.setState({
        modalIsOpen: false
      })
      alert('Status alterado com Sucesso!')
      await this.getAmbassador();
    }

    Ambassadors = () => {
        const { ambassadors, isLoading } = this.state;
    
        if (isLoading) {
          return (
            <tr>
              <td>
                <span className="w-100">
                  <img src={logo} width="50px" alt="loading..." />
                  <span className="m-4">Carregando registros... </span>
                </span>
              </td>
            </tr>
          );
        }

        return ambassadors.length ? (
            ambassadors.map((item) => {
            return (
              <tr key={item.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{item.id}</span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{item.name ? item.name : ""}</span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">{item.type ? item.type : ""}</div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{item.total_indicated ? item.total_indicated : "0"}</span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{item.received ? item.received : "0.00"}</span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{item.to_receive ? item.to_receive : "0.00"}</span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{item.total_appointments ? item.total_appointments : "0"}</span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                      {item.status !== 0 ? (
                          item.status === 2 ? (
                            <span className="mr-2 status-ambassador-active">Ativo</span>
                          ) : (
                            <span className="mr-2 status-ambassador-to-canceled">A Cancelar</span>
                          )
                      ) : (
                        <span className="mr-2 status-ambassador-canceled">Cancelado</span>
                      ) }
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{item.confirmation !== 0 ? "Sim" : "Não"}</span>
                  </div>
                </td>
                <td>
                    <div>
                        <Link to={{
                          pathname: `/admin/ambassador/${item.id}`
                        }}>
                          <button className="btn btn-success">Pagamentos</button>
                        </Link>
                    </div>
                </td>
                <td>
                    <div>
                        <button className="btn btn-primary" onClick={() => {this.openModalStatus(item.id)}}>Status</button>
                    </div>
                </td>         
              </tr>
            );
          })
        ) : (
          <tr>
            <td>
              <span className="w-100">
                <span className="m-4"> Não há registro </span>
              </span>
            </td>
          </tr>
        );
    };

    Paginate = () => {
      const { paginate } = this.state

      if (paginate === null) return <> </>;

      const { totalPages } = paginate;
      
      return (
        //Pagination Pagamentos
        <Pagination className="justify-content-center d-flex mt-3">
          <div className="d-flex ul-in-line">
            <Paginate
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={this.search}
              previousLabel="Anterior"
              nextLabel="Proximo"
              activeClassName="ul-li-active"
            />
          </div>
        </Pagination>
      );
    }

    search = async (e) => {
      const { searchFields } = this.state;
  
      let params = {};
  
      await searchFields.map((item) => {
        if (this.state[item] !== "") {
          params[item] = this.state[item];
        }

        return params[item];
      });
  
      return this.getAmbassador(e, { ...params });
    };

    render(){
        return(
            <>
                <Container className="mt--7" fluid>
                  <Modal isOpen={this.state.modalIsOpen}>
                    <ModalHeader>Alterar Status</ModalHeader>
                    <ModalBody>
                      Alterar o Status do embaixador:
                      <select className="form-control select-ambassador">
                        <option>Selecione o Status</option>
                        <option onClick={() => {this.setState({statusSelected: 2})}}> Ativo </option>
                        <option onClick={() => {this.setState({statusSelected: 1})}}> A Cancelar </option>
                        <option onClick={() => {this.setState({statusSelected: 0})}}> Cancelado </option>
                      </select>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={() => {this.updateStatus()}}>Salvar</Button>{' '}
                      <Button color="secondary" onClick={() => {this.setState({modalIsOpen: false})}}>Cancel</Button>
                    </ModalFooter>
                  </Modal>
                    <Row>
                        <div className="col">
                        <Card className="shadow">
                            <div className="d-flex justify-content-between flex-row align-items-end">
                              <InputGroup style={{ padding: 10 }}>
                                <InputGroupAddon addonType="prepend">Pesquisa</InputGroupAddon>
                                <Input value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} />
                                <InputGroupAddon addonType="prepend">
                                <Button color="primary" type="button" onClick={() => this.search()}>
                                    <i className="fas fa-search"></i>
                                </Button>
                                </InputGroupAddon>
                              </InputGroup>

                              <div className="container-buttons-ambassador">
                                <button className="button-ambassador">Exportar</button>
                                <Link to="/admin/ambassador/set">
                                <button className="button-ambassador">Nomear Embaixador</button>
                                </Link>
                              </div>
                            </div>

                            <Table className="align-items-center table-hover" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">ID</th>

                                    <th scope="col">Nome</th>

                                    <th scope="col">Tipo</th>

                                    <th scope="col">Total indicados</th>

                                    <th scope="col">Recebido</th>

                                    <th scope="col">Receber</th>

                                    <th scope="col">Total Consul.</th>

                                    <th scope="col">Status</th>

                                    <th scope="col">Confirmação</th>
                                    
                                    <th scope="col">Ações</th>

                                    <th scope="col">Alt. Status</th>
                                </tr>
                            </thead>
                            <tbody>{this.Ambassadors()}</tbody>
                            </Table>
                            <nav>{this.Paginate()}</nav>
                        </Card>
                        </div>
                    </Row>
                </Container>            
            </>
        );
    }
}

export default ListEmbassador;