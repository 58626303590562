import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as AuthCreators, Types } from "../ducks/auth";
import { push } from "connected-react-router";

export function* authorize({ payload: { email, password } }) {
  const credentials = { email, password };

  yield put({ type: Types.AUTH_ERROR_CLEAR });

  try {
    const { data } = yield call(api.post, "auth/login-painel", credentials);

    if (data.status === "not_ok") {
      return yield put({ type: Types.AUTH_ERROR_PANEL });
    }

    if (data.entity === "doctor") {
      yield put(AuthCreators.setDashInfo(data.dashCount));
      yield put(AuthCreators.authSuccess(data.doctor));

      localStorage.setItem("token", data.token);
      localStorage.setItem("doctor", JSON.stringify(data.doctor));
      localStorage.setItem("entity", "doctor");
      yield put({ type: Types.AUTH_CLEAR_ERROR_PANEL });

      yield put(push("/admin/index"));
    }

    if (data.entity === "regional") {
      yield put(AuthCreators.authSuccessRegional(data.regional));

      localStorage.setItem("token", data.token);
      localStorage.setItem("regional", JSON.stringify(data.regional));
      localStorage.setItem("entity", "regional");
      yield put({ type: Types.AUTH_CLEAR_ERROR_PANEL });

      yield put(push("/regional/details"));
    }
  } catch (err) {
    const error = err.toString();
    let message;

    console.log(err);

    if (error.indexOf("500") !== -1) {
      message = "Erro interno servidor";
    } else if (error.indexOf("403") !== -1) {
      message = "Credenciais Invalidas";
    } else {
      message = "Algo deu errado";
    }

    // switch (error.status) {
    //   case 500:
    //     message = "Erro interno servidor";
    //     break;
    //   case 403:
    //     message = "Credenciais Invalidas";
    //     break;
    //   default:
    //     message = "Algo deu errado";
    // }

    yield put({ type: Types.AUTH_ERROR, payload: { message } });
    yield put({ type: Types.AUTH_CLEAR_ERROR_PANEL });
    localStorage.removeItem("token");
  }
}
