import React from "react";
import { Container, Row, Col } from "reactstrap";

class UserHeader extends React.Component {
  render() {
    const { doctor } = this.props;

    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-6 d-flex align-items-center"
          style={{
            minHeight: "130px",
            backgroundImage:
              "url(" + require("assets/img/theme/medicos_brasil_background.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-5" />

          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12">
                <h2 className="display-4 text-white"> 
                  {
                    doctor ?
                      doctor.hasOwnProperty('formal_name')
                      ? (
                        `${this.props.doctor.formal_name}`
                      ) : null
                    : null
                  }
                </h2>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
