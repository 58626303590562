import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  // baseURL: "https://mdbhomolog.soterotech.net/api/",
  baseURL: process.env.REACT_APP_BASE_URL_API + "/api/",
});

api.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    const token = getToken();
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
