import React from "react";
import logo from "../../../../assets/img/theme/loading.gif";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from "reactstrap";

class ModalDoctor extends React.Component {
  state = {
    isLoading: false,
  };

  handleSubmit = async () => {
    this.toggleLoading();

    await this.props.handlesubmit();

    this.toggleLoading();
  };

  toggleLoading = () => {
    const { isLoading } = this.state;

    this.setState({ isLoading: !isLoading });
  };

  render() {
    const { isLoading } = this.state;
    const { className, label } = this.props;

    return (
      <Modal toggle={this.props.toggle} {...this.props} size="lg" className={className} centered>
        <ModalHeader toggle={this.props.toggle} charCode="X" className="text-center">
          <div className="d-flex justify-content-center w-100">
            <h1> {label} </h1>
          </div>
        </ModalHeader>
        <ModalBody>{this.props.content}</ModalBody>
        <ModalFooter>
          <ButtonGroup size="lg">
            {isLoading ? (
              <span className="w-100">
                <img src={logo} width="50px" alt="loading..." />
              </span>
            ) : (
              <Button color="success" type="submit" onClick={(e) => this.handleSubmit()}>
                Confirmar
              </Button>
            )}

            <Button onClick={this.props.toggle} type="button">
              Cancelar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalDoctor;
