import React from "react";
import { Container } from "reactstrap";
import Header from "components/Headers/Header.jsx";
import TableRegionalsSummary from "../regionalSummary/table/tableListRegionalsSummary.jsx";

class Index extends React.Component {
  render() {
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <TableRegionalsSummary {...this.props} />
        </Container>
      </>
    );
  }
}

export default Index;
