import decode from "jwt-decode";

export const TOKEN_KEY = "token";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const loggedIn = () => {
  const token = this.getToken(); // GEtting token from localstorage
  return !!token && !this.isTokenExpired(TOKEN_KEY); // handwaiving here
};

export const isTokenExpired = token => {
  try {
    const decoded = decode(TOKEN_KEY);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired. N
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
};

export const getProfile = () => {
  // Using jwt-decode npm package to decode the token
  return decode(this.getToken());
};
