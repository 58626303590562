import Login from "./views/examples/Login";
import Register from "./views/examples/Register";

var Routes = [
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-single-02 text-yellow",
    component: Login,
    layout: "/auth"
  }
];

export default Routes;
